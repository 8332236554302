<template>
  <div>
    <!--loading-->
    <vs-progress color="primary" :height="1" v-if="isLoadingInitData"/>

    <div class="vx-row mb-3" v-if="initData.poD.id_po">
      <div class="vx-col lg:w-9/12 w-full">
        <vx-card>
          <vs-tabs alignment="fixed" v-model="activeTab">
            <vs-tab label="PO Header" icon-pack="feather">
              <TabHeader :isActive="activeTab === 0" :initData="initData"/>
            </vs-tab>
            <vs-tab label="PO Barang" icon-pack="feather">
              <TabBarang :isActive="activeTab === 2" :initData="initData"/>
            </vs-tab>
            <vs-tab label="Detail Pajak" icon-pack="feather">
              <TabTax :isActive="activeTab === 1" :idPo="initData.poD.id_po" :idPoD="initData.poD.id" :po="initData.po"/>
            </vs-tab>
            <vs-tab label="Detail Akunting" icon-pack="feather">
              <TabAkunting :isActive="activeTab === 2" :idPo="initData.poD.id_po" :idPoD="initData.poD.id" :poD="initData.poD"/>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </div>

      <div class="vx-col lg:w-3/12 w-full mt-3 lg:mt-0">
        <!--approval progress-->
        <vx-card>
          <div class="flex items-center mb-base">
            <feather-icon icon="LayersIcon" svgClasses="h-5 w-5" class="mr-2"/>
            <h5 class="font-medium">Approval Progress</h5>
          </div>
          <ApprovalProgress
            ref="approvalProgress"
            type="PO-TERMIN"
            :id-ref="$route.params.idPoD"
            :isShowSigningAction="true"
            @signingClicked="onClickSigningAction"/>
        </vx-card>
      </div>
    </div>

    <!--modals-->
    <PoTerminApprovalSigning :isActive.sync="modalSigning.active"
                       :idPoD="modalSigning.idPoD"
                       :status="modalSigning.status"
                       @success="onSigned"/>
  </div>
</template>

<script>
import PoRepository from '@/repositories/proyek/po-repository'
import modulePoTerminApproval from '@/store/modules/approvals/po-termin-approval/po-termin-approval.store'
import { mapState } from 'vuex'

export default {
  name: 'PoTerminApprovalShow',
  components: {
    TabHeader: () => import('@/views/pages/approvals/po-termin-approval/parts/TabHeader'),
    TabBarang: () => import('@/views/pages/approvals/po-termin-approval/parts/TabBarang'),
    TabTax: () => import('@/views/pages/approvals/po-termin-approval/parts/TabTax'),
    TabAkunting: () => import('@/views/pages/approvals/po-termin-approval/parts/TabAkunting'),
    PoTerminApprovalSigning: () => import('@/views/pages/approvals/po-termin-approval/PoTerminApprovalSigning'),
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress')
  },
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isLoadingInitData: false,
      poData: {},
      initData: {
        poD: {},
        po: {},
        poBarang: []
      },
      modalSigning: {
        active: false,
        idPoD: this.$route.params.idPoD,
        status: null
      }
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.approvals.poTerminApproval.activeTab
      },
      set (value) {
        this.$store.commit('approvals/poTerminApproval/SET_ACTIVE_TAB', value)
      }
    },
    ...mapState({
      dataTabGeneral: state => state.approvals.poTerminApproval.tabGeneral,
      dataTabTax: state => state.approvals.poTerminApproval.tabTax,
      dataTabAkunting: state => state.approvals.poTerminApproval.tabAkunting
    })
  },
  methods: {
    onSigned () {
      this.getInitData()
      this.$refs.approvalProgress.refresh()
    },

    getInitData () {
      this.isLoadingInitData = true

      const idPoD = this.$route.params.idPoD
      PoRepository.showTermin(idPoD)
        .then(response => {
          this.initData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    onClickSigningAction (status) {
      this.modalSigning.status = status
      this.modalSigning.active = true
    }
  },
  beforeCreate () {
    this.$store.registerModule(['approvals', 'poTerminApproval'], modulePoTerminApproval)
  },
  beforeDestroy () {
    this.$store.commit('approvals/poTerminApproval/RESET_STATE')
    this.$store.unregisterModule(['approvals', 'poTerminApproval'])
  }
}
</script>
